import { createRouter,createWebHashHistory } from "vue-router";
const routes = [
  {
    path:'/',
    name:'home',
    component:()=>import('../views/home/index-home.vue')
  },
  {
    path:'/:catchAll(.*)',
    redirect: '/'
  }
]
const router = createRouter({
  history:createWebHashHistory(),
  routes
})
export default router
