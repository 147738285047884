// import { createApp } from 'vue'
// import './style/style.css'
// import './utils/rem.js'
// import router from './router'
// import App from './App.vue'
// import vuescroll from 'vuescroll' //引入vuescroll
// import 'vuescroll/dist/vuescroll.css' //引入vuescroll样式
// import ElementPlus from 'element-plus'
// import 'element-plus/dist/index.css'
// import animateOnScroll from './router/animateOnScroll'


// App.directive('animate-on-scroll', animateOnScroll)

// createApp(App).use(router).use(vuescroll).use(ElementPlus).mount('#app')



// main.js

import { createApp } from 'vue'
import './style/style.css'
import './utils/rem.js'
import router from './router'
import App from './App.vue'
import vuescroll from 'vuescroll' // 引入 vuescroll
import 'vuescroll/dist/vuescroll.css' // 引入 vuescroll 样式
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import animateOnScroll from './router/animateOnScroll'

const app = createApp(App)

// 注册自定义指令
app.directive('animate-on-scroll', animateOnScroll)

app.use(router)
  .use(vuescroll)
  .use(ElementPlus)
  .mount('#app')


// import { createApp } from 'vue'
// import App from './App.vue'
// import router from '@/router/index'
// import '@/style/style.css'
// import { Swiper, SwiperSlide } from 'swiper/vue'
// import 'swiper/css' // Ensure you import Swiper CSS as well

// const app = createApp(App)

// app.component('Swiper', Swiper)
// app.component('SwiperSlide', SwiperSlide)
// createApp(App).use(router).mount('#app')
