// src/directives/animateOnScroll.js

import { onBeforeUnmount } from 'vue'

export default {
  mounted (el, binding) {
    const animateClass = binding.value

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          el.classList.add('animate__animated', animateClass)
        } else {
          el.classList.remove('animate__animated', animateClass)
        }
      })
    })

    observer.observe(el)

    onBeforeUnmount(() => {
      observer.unobserve(el)
    })
  },
}
